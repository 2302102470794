* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.login_main .box {
    box-shadow: 1px 1px 5px black;
}

.loginbox {
    box-shadow: 0px 0px 5px rgb(165, 159, 159);
}

.login_from div input {
    border: 1px solid rgb(167, 98, 98);
    border-radius: 30px;
    outline: none;
    padding: 12px 15px;
    width: 100%;
    font-size: 12px;
}

.login_from div input:focus {
    border:1px solid rgb(141, 60, 60)
}

.login_from div span {
    background-color: white;
    font-size: 13px;
    color: rgb(167, 98, 98);
    left: 20px;
    top: -12px;
    padding: 0px 5px;
}

.social_links div{
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: grid;
    place-items: center;
    font-size: 16px;
    color: white;
    cursor: pointer;
}
.social_links div:nth-child(1){
    background-color: rgb(59,89,152);
}

.social_links div:nth-child(2){
    background-color: rgb(29,161,242);
}
.social_links div:nth-child(3){
    background-color: rgb(234,67,53);
}
.social_links div:nth-child(1):hover{
    box-shadow: 0px 2px 4px black;
}
.social_links div:nth-child(2):hover{
    box-shadow: 0px 2px 4px black;
}
.social_links div:nth-child(3):hover{
    box-shadow: 0px 2px 4px black;
}
.register_btn:hover{
    background-color: white;
    color: rgb(41, 38, 38);
    transition: 0.2s all;
}

/* Nav bar */

.side_bar::-webkit-scrollbar{
    width: 5px;
}

.side_bar::-webkit-scrollbar-thumb{
    background-color: #7f7fe994;
    border-radius: 30px;
    padding: 20px 0px;
}

.nav_options svg{
    cursor: pointer;
}
.aside_icons .icons div{
    padding-top: 4px;
    padding-bottom: 3px;
    gap: 5px;
    align-items: center;

}
/* .aside_icons .icons div div:hover{
    background-color: rgba(247, 243, 243, 0.651);
    border-radius: 20px;
} */
.submenu p, .submenu div{
    padding: 5px 20px;
}
.submenu p:hover{
    background-color: rgba(243, 240, 240, 0.486);
}
.aside_icons .icons div .submenu{
    padding-top: 0px;
    padding-bottom: 0px;
}
.aside_icons .icons div div{
    /* background-color: red; */
    margin-top: -4px;
}
.profile_img{
    background: linear-gradient(to right, pink, blue, orange);
    border-radius: 100%;
    padding: 3px;
}
.menu{
    font-size: 12px;
}
.submenu{
    font-size: 11px;
}
.side_bar svg{
    height: 15px;
}
.side_bar .icons div:nth-child(4) svg, .side_bar .icons div:nth-child(5) svg{
    height: 21px;
    margin-left: -3px;
}
/* .side_bar .icons div:nth-child(4) svg, .side_bar .icons div:nth-child(6) svg{
    height: 16px;
    font-weight: bold;
} */
.menu_title{
    padding: 5px 0px;
}
.submenu ul li{
    user-select: none;
}
.submenu ul li:hover{
    color: rgb(111, 111, 226);
}

/* Add Employee */
.upload {
    border:1px solid rgb(60, 175, 175);
    position: relative;
    border-radius: 5px;
    height: 40px;
    display: flex;
    align-items: center;

}
.upload span{
    font-size: 12px;
    padding-left: 60px;
    color: #222;
}
.upload button, .upload label{
    position: absolute;
    background-color: teal;
    font-size: 12px;
    height: 100%;
    padding: 0px 10px;
    color: white;
    cursor: pointer;
}
.upload .cancel_btn{
    left: 0;
    background-color: rgb(228, 99, 99);
    border-start-start-radius: 3px;
    border-end-start-radius: 3px;
}
.upload .upload_btn{
    display: flex;
    align-items: center;
    right: 0;
    border-start-end-radius: 3px;
    border-end-end-radius: 3px;
}
select{
    width: 100%;
    border:1px solid rgb(214, 207, 207);
    font-size: 12px;
    border-radius: 5px;
    outline: none;
    padding: 10px 2px;
}
select[name=add_designation]{
    padding: 10px 0px;
}



table tbody tr .client_list_profile_img img{
    border-radius: 100%;
    box-shadow: 0px 0px 5px lightgrey;
    padding: 2px;
    height: 28px;
}
table, tr, td{
    padding: 5px;
    white-space: nowrap;
}
td{
    /* margin: 0px 50px; */
    min-width: 80px;
}
table tbody tr:nth-child(even){
    background-color: rgba(245, 240, 240, 0.808);
}
table tbody tr:hover{
    background-color: rgba(241, 237, 237, 0.767) ;
}
table tbody .action{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-top: 10px;
}
table tbody .action button{
    width: 20px;
    height: 20px;
    border-radius: 100%;
    font-size: 10px;
    display: grid;
    place-items: center;
    color: white;
}
.edit_btn{
    background-color: rgba(0, 128, 0, 0.733);
}
.del_btn{
    background-color: rgba(255, 0, 0, 0.671)
}
.mail_btn{
    background-color: rgb(80, 80, 226);
}

.admin_table th{
    font-weight: 600;
    padding: 3px 0px;
}

.td_pending{
    border:1px solid orange;
    color: orange;
    padding: 3px 8px;
    border-radius: 3px;
}

.td_leave{
    border:1px solid red;
    color: red;
    padding: 3px 8px;
    border-radius: 3px;
}
.td_weekend{
    border:1px solid rgb(79, 192, 236);
    color: rgb(79, 192, 236);
    padding: 3px 8px;
    border-radius: 3px;
}
.td_present{
    border:1px solid rgb(40, 172, 40);
    color: rgb(40, 172, 40);
    padding: 3px 8px;
    border-radius: 3px;
}

.active_tab{
    padding: 5px;
    background-color: rgb(100, 100, 233);
    color: white;
    border-radius: 5px;
}

.add_client_form input, .add_employee_form input{
    width: 100%;
    outline:none;
    border:1px solid rgb(221, 212, 212);
    font-size: 12px;
    padding: 8px;
    border-radius: 5px;
}
textarea{
    border:1px solid rgb(221, 212, 212);
}
.add_client_form input:focus, .add_employee_form input:focus, textarea:focus{
    border-bottom: 1px solid rgb(91, 91, 201);
}

.add_client_upload_area label{
    background:linear-gradient(to right, purple, rgb(216, 173, 94));
    font-size: 12px;
    border-radius: 3px;
    color: white;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 40px;
}

/* employee attendance dialog box */
.dialog{
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.521);
    z-index: 999999;
    position: absolute;
    display: grid;
    place-items: center;
}
.dialog_box{
    width: 500px;
    min-height: 100px;
    background-color: white;
    border-radius: 10px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* overflow: auto; */

}

.dialog_box input{
    border:1px solid lightgrey;
    box-shadow: inset 0px 0px 1px lightgrey;
    outline:none;
    width: 100%;
    height: 40px;
    border-radius: 30px;
    padding: 2px 15px;
    font-size: 12px;
    padding-right: 35px;
}
.dialog_box input:focus{
    border-bottom: 1px solid rgb(104, 104, 231);
}
.dialog_search_icon{
    right: 0;
    top: 0;
    height: 100%;
    padding: 0px 20px;
    background-color: rgb(117, 117, 230);
    color: white;
    border-start-end-radius: 30px;
    border-end-end-radius: 30px;
    display: grid;
    place-items: center;
    font-size: 12px;
    cursor: pointer;
}
.employee_list li{
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}
.employee_list li:hover{
    background-color: rgba(228, 222, 222, 0.336);
}

progress{
    width: 100%;
    height: 5px;
}

.billing_table{
    width: 100%;
    margin-top: 10px;
}

.billing_table table{
    width: 100%;
    font-size: 12px;
}
.billing_table td{
    font-size: 12px;
    font-weight: 600;
    text-align: center;
}

.billing_table input {
    border:none;
    padding: 10px 5px;
}


.popup_form div{
    display: flex;
    gap: 10px;
    margin-top: 10px;
}
.overlay{
    /* display: none; */
    position: fixed;
}
.active_popup{
    display: block;
}
.popup_form div input, .popup_form div textarea{
    width: 100%;
    outline: none;
    border:1px solid rgb(202, 198, 198);
    border-radius: 5px;
    padding: 8px;
    font-size: 12px;
}
.popup_form div input:focus, .popup_form div textarea:focus{
    border-bottom:1px solid rgb(116, 116, 238);
}
.popup_form div label{
    width: 100%;
    background-color: rgb(134, 134, 226);
    color: white;
    border-radius: 3px;
    text-align: center;
    padding: 3px 0px;
    border:2px dashed white;
    cursor: pointer;
    font-size: 15px;
}
.popup_form div button{
    font-size: 12px;
    background-color: teal;
    color: white;
    border-radius: 3px;
    padding: 8px 10px;
    margin-top: 5px;
}
.popup_title {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.popup_title i{
    cursor: pointer;
    background-color: tomato;
    padding: 5px 6px;
    font-size: 12px;
    border-radius: 3px;
    color: white;
}
.popup_title i:hover{
    background-color: rgb(245, 86, 59)
}


.payroll_overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.459);
    z-index: 999;
    display: grid;
    place-items: center;
    display: none;
}
.payroll_dialog_box{
    width: 40%;
    height: auto;
    
    border-radius: 5px;
    background-color: white;

}
.payroll_dialog_box_title{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    font-size: 14px;
}
.payroll_dialog_box_title i{
    background-color: tomato;
    color: white;
    padding: 5px;
    border-radius: 3px;
    cursor: pointer;
}
.payroll_dialog_box form p{
    font-size: 12px;
}
.payroll_dialog_box form input, .payroll_dialog_box form select{
    outline:none;
    border:1px solid rgb(240, 232, 232);
    border-radius: 5px;
    height: 40px;
    font-size: 12px;
}
.payroll_dialog_box button{
    padding: 2px 10px;
    border-radius: 3px;
    font-size: 12px;
    width: 120px;
    height: 30px;
}

.dropdown_menu_parent{
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
}
.dropdown_menu{
    background-color: rgb(120, 120, 245);
    font-size: 12px;
    width: 180px;
    border-radius: 5px;
    overflow: hidden;
    position: absolute;
    top: 22px;
    right: 0px;
    transition: 0.3s all;
    visibility: hidden;
}
.dropdown_menu_active{
    top:22px;
    visibility: visible;
}

.dropdown_menu ul li{
    padding: 8px 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid rgba(101, 101, 218, 0.815);
    cursor: pointer;
    user-select: none;
}
.dropdown_menu ul li:last-child{
    border-bottom: none;
}
.dropdown_menu ul li:hover{
    background-color: rgb(82, 82, 197);
}

.attendance_top{
    width: 100%;
    height: auto;
    border-radius: 5px;
    display: flex;
    gap: 10px;
}
.attendance_top .date{
    border:none;
    outline:none;
    padding:5px 10px;
    background: transparent;
    border: 1px solid #222;
    border-radius: 5px;
    color: #222;
}

.attendance_top .search{
    width: 90%;
    border:none;
    outline: none;
    border-radius: 30px;
    padding: 5px 10px;
    font-size: 14px;
    color: #222;
}

.attendance_table{
    width: 100%;
    margin-top: 20px;
}

.attendance_table table tbody tr:nth-child(even){
    background-color: rgb(233, 229, 229);
}
.attendance_table table tbody tr td{
    min-width: 120px;
}
